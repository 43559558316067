<template>
    <Container :loading="loading">
            <div v-for="(item) in list" :key="item.code" class="item" @click="openArticleDetail(item)">
                <img :src="item.face_img" alt="">
                <!-- <span class="index">{{index + 1}}</span> -->
            </div>
    </Container>

</template>

<script>
import article from '@/mixins/article'
import { fetchList  } from '@/api/article'
export default {
    mixins: [article],
    data() {
        return {
            loading: false,
            list: []
        }
    },

    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            fetchList({
                p: [{
                    category: "tscp",
                    page: 1,
                    rows: 10,
                }]
            }).then(res => {
                if (Array.isArray(res) && res.length > 0) {
                    if (res[0].data && Array.isArray(res[0].data.list)) {
                        this.list = res[0].data.list;
                    }
                }
            })
        }
    }

    

}
</script>

<style lang="less" scoped>


    .item {
        position: relative;
        cursor: pointer;
            img {
                    width: 100%;
                }
            
            span {
                position: absolute;
                top: 24px;
                left: 24px;
                font-size: 20px;
                font-weight: bold;
            }
    }

</style>